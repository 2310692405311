import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    indexList: [],
    list001: [
      { name: '正在营业', number: 218, checked: true },
      { name: '汽车维修', number: 68, checked: false },
      { name: '汽车维修', number: 68, checked: false },
      { name: '汽车维修', number: 68, checked: false },
      { name: '汽车维修', number: 68, checked: false },
      { name: '汽车维修', number: 68, checked: false },
      { name: '汽车维修', number: 68, checked: false },
    ],
    selectedIndices: [],
    token: '',
    userInfo: {},
  },
  getters: {
  },
  mutations: {
      setIndexList(state, data) {
        state.indexList = data;
      },
      setlist001(state, data) {
        state.list001 = data;
      },
      // 添加或删除选中的索引
      SET_SELECTED_CLEAR(state) {
        state.selectedIndices = [];
      },
      // 添加或删除选中的索引
      SET_SELECTED_INDICES(state, index) {
        const idx = state.selectedIndices.indexOf(index);
        if (idx === -1) {
          state.selectedIndices.push(index);
        } else {
          state.selectedIndices.splice(idx, 1);
        }
      },
      setToken(state, data) {
        state.token = data;
      },
      setUserInfo(state, data) {
        state.userInfo = data;
      },
      setlist001checked(state, dataStr) {
        // 将 dataStr 字符串分割成数字数组
        let dataIds = dataStr.split(',').map(Number); // 使用 map 将每个字符串转换为数字
        
        let list = [];

        state.list001.forEach(item => {
          if (dataIds.includes(item.id)) {
            item.checked = true;
            list.push(item);
          }else{
            item.checked = false;
            list.push(item);
          }
        });

        state.list001 = list;

      }
  },
  actions: {
  },
  modules: {
  }
})
